/**
 * This file contains applications predefined values.
 */
export class AppSettings {
  /*
   * Application name still needs to be changed manually in the following files:
   * 	- package.json (follow the rules at: https://docs.npmjs.com/files/package.json#name)
   * 	- angular.json
   */

  public static readonly appName = "Industry Premium Portal";
  public static readonly sectionName = "Start Page";
  public static readonly logLevel = "Trace";
  public static readonly teamName = "CARBON";

  /**
   * Url Patterns for API calls that bypass loading animation
   * Escape special characters used by Regex for proper behaviour
   * But don't use Regex, external component doesn't support it.
   */

  public static readonly filteredUrlPatterns: ReadonlyArray<string> = [];
}
