<div class="bg">
  <div class="centered">
    <img src="assets/img/sie-logo-white-rgb.svg" alt="Siemens" />

    <h1 class="title d-none d-sm-block">{{ appName }}</h1>
    <h5 class="subtitle d-none d-sm-block">{{ appClaim }}</h5>

    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>
