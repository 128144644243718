import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SubscriberBase } from "../../abstract/subscriber-base";
import { AppSettings } from "../../config/app-settings.config";
import { AuthenticationService } from "../../authentication";

@Component({
  selector: "app-page-loader",
  templateUrl: "./page-loader.component.html",
  styleUrls: ["./page-loader.component.scss"],
})
export class PageLoaderComponent extends SubscriberBase implements OnInit {
  readonly appName: string = AppSettings.appName;
  readonly appClaim: string = AppSettings.sectionName;

  private returnUrl: string;

  constructor(private authService: AuthenticationService, private route: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.routeCheck();
    this.checkInfoState();
  }

  private routeCheck() {
    const routeSub = this.route.queryParams.subscribe((params) => {
      this.returnUrl = params?.returnUrl || "/";
    });
    this.addSub(routeSub);
  }

  private checkInfoState(): void {
    const authSub = this.authService.authStateChecked().subscribe((infoChecked) => {
      if (infoChecked) {
        this.router.navigateByUrl(this.returnUrl);
      }
    });
    this.addSub(authSub);
  }
}
